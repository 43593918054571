/*-------------------------------------------------
# Dialogfenster
#-------------------------------------------------*/
.dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    display: none;
}

.dialog .grey-bg{
    display: block;
    background: $dark-gray;
    width: 100%;
    height: 100%;
}

.dialog-content {
    position: absolute;
    z-index: 101;
    width: 60%;
    left: 20%;
    top: -580px;
}

.close {
    color: $white;
    cursor: pointer;
    top: -30px;
    right: -40px;
    z-index: 101;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 28px;
    text-align: center;
    line-height: 18px;
	-webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
	font-size: 40px;
}

.close:hover{
	color: $secondary-color;
    text-decoration: none;
}
/*-------------------------------------------------
# Search
#-------------------------------------------------*/
#search input[type="text"] {
    width: 70%;
    padding: 16px 30px 15px;
    font-size: 42px;
    color: $black;
    height: auto;
    margin-bottom: 0 !important;
}

#search input[type="submit"] {
    width: calc(30% - 24px);
    padding: 24px 12px;
    margin: 0px;
    margin-left: 20px;
    font-size: 42px;
    border: none;
    text-transform: uppercase;
	background-color: $primary-color;
	color: $white;
	font-family: 'robotobold', Helvetica, Roboto, Arial, sans-serif;
	cursor:pointer;
	margin-bottom: 0 !important;
}

#search input[type="submit"]:hover {
	background-color: $secondary-color;
	color: $white;
	-webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

#search form{
    padding: 30px 0 20px;
}

#search-output {
    min-height: 300px;
    width: 70%;
    
    p{
    	color: $white;
    }
}

#search-output ul{
    padding: 0; 
    margin-left: 0px;
    
    li{
    	margin:0;
    }
}

.search-output-arrow {
    border-color: $primary-color transparent;
    background: transparent;
    border-style: solid;
    border-width: 0px 16px 16px;
    height: 0px;
    position: relative;
    left: 45%;
    margin-left: -8px;
    width: 0px;
    padding: 0px;
    border-radius: 0px;
}

#search-output li.search-output-title {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
	padding: 5px 20px;
    font-size: 24px;
    list-style: none;
    background-color: $primary-color;
    color: $white;
    font-family: 'robotobold', Helvetica, Roboto, Arial, sans-serif;
}

#search-output li{
    list-style: none;
}

#search-output li a {
    display: block;
    background: $white;
    border-bottom: 1px solid $medium-gray;
    color: $black;
    font-size: 20px;
    padding: 8px 20px;
	text-decoration: none;
}

#search-output li a:hover{
    background-color: $medium-gray;
    text-decoration: none;
	-webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
	text-decoration: none;
}

#search-output li.search-output-more a{
    background-color: $primary-color;
    padding: 4px 16px;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
    font-size: 20px;
    color: $white;
    font-family: 'robotobold', Helvetica, Roboto, Arial, sans-serif;
	text-decoration:none;
}

#search-output li.search-output-more a:hover{
    background-color: $secondary-color;
	color: $white;
    text-decoration: none;
	cursor:pointer;
	-webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
}
