@media only screen and (max-width: 1799px) {
	
	.grid-container{
		padding-left: 50px;
		padding-right: 50px;
		max-width: 1600px;
	}

	#mainheader{

		#logo {
			margin: 40px 120px 20px 0px;
		}
		
		&::before {

    margin-top: 110px;
		}

		#topnav{
			font-size: 20px;
			
			ul li{
				margin-left: 15px;
				
				a i{
					margin-right: 2px;
				}
			}
		}
	}
	
	#mainnav{
		margin-top: 15px;
		
		ul li{
				
			a{
				padding: 0px 0px 15px 0px;
				font-size: 24px;
			}
				
			ul{
				min-width: 200px;
				
				li a{
					padding: 5px 15px;
					font-size: 20px;
				}
			}
		}
	}
	
	.bigtext{
		font-size: 28px;
	}
	
	#content{
		padding-top: 130px;
		
		.quote{
			font-size: 45px;
			
			.author{
				font-size: 20px;
			}
		}

		.small-quote {
			font-size: 26px;
		}
		
		.stars{
			font-size: 28px;
			line-height: 58px;
		}
	}
	
	#start-header{
		padding-top: 70px;
		
		.front-img{
			top: -10px;
			width: 800px;
			max-height: calc(100% + 10px);
		}
		
		.h1-style{
			font-size: 72px;
		}
		
		.blue-transparent-bg, .green-transparent-bg{
			min-height: 213px;
		}
		
		.blue-transparent-bg{
			width: 472px;
			padding: 30px 100px 30px calc((100% - 950px)/2);
			
			.flex-box{
				display:block;
				
				.col{
					margin-bottom: 15px;
				}
			}			
		}
		
		.green-transparent-bg{
			width: 278px;
			padding: 30px calc((100% - 950px)/2) 30px 100px;
			
			img{
				width:150px;
				margin-left: -175px;
			}
		}
	}
	
	#lp-header{
		padding-top: 540px;
		
		.blue-transparent-bg{
			width: 472px;
			min-height: 213px;
			padding: 30px 100px 28px calc((100% - 950px)/2);
			
			.flex-box{
				display:block;
				
				.col{
					margin-bottom: 15px;
				}
			}
			
		}
		
		.green-transparent-bg{
			width: 278px;
			min-height: 191px;
			padding: 30px calc((100% - 950px)/2) 28px 100px;
			
			img{
				width:150px;
				margin-left: -175px;
			}
		}
	}
	
	#lp-header-img{
		.blue-transparent-bg{
			width: 472px;
			min-height: 213px;
			padding: 30px 100px 28px calc((100% - 950px)/2);
			
			.flex-box{
				display:block;
				
				.col{
					margin-bottom: 15px;
				}
			}
			
			a.cert{
				left: calc(((100% - 950px)/2) + 480px);
			}
		}
		
		.green-transparent-bg{
			width: 278px;
			min-height: 191px;
			padding: 30px calc((100% - 950px)/2) 28px 100px;
			
			img{
				width:150px;
				margin-left: -175px;
			}
		}
	}
	
	.blue-bg{
		max-width: 100%;
	}
	
	.text-media-left, .text-media-right{
		padding: 30px 0;
		
		&>img{
			width: calc(50% - 110px);
		}
		
		.cell{
			padding: 0;
		}
	}
	
	.text-login-right{
		form{
			width: 308px;
			padding: 60px calc((100% - 950px)/2) 60px 70px;
		}
		
		&>.grid-container{
			padding: 40px 0;
		}
	}
	
	.green-bg{
		max-width: 100%;
	}
	
	.slider{
		.slider-nav{
			margin-top: -24px;
			font-size: 48px;
			line-height: 48px;
			
			&.left-arrow{
				left: -50px;
			}
			
			&.right-arrow{
				right: -50px;
			}
		}
	}
	
	footer{
		ul{
			width: 100%;
		}
	}
	
	
	
	.ansprechpartner-neu .grid-margin-x > .large-6 {
    width: calc(33% - 1.36364rem);
}
	
	.ansprechpartner-neu {
    padding: 50px 0 0 0px;
}
	
	
}

@media only screen and (max-width: 1600px) {
	#start--header--new .h1-style a {
		font-size: 22px;
		padding: 12px 30px 12px 30px;
	}

	.h1-style {
		font-size: 50px;
	}

	#logo {
		padding: 0; 
	}

	.green-bg {
		padding-top: 40px;
    	padding-bottom: 30px;
	}
}

@media only screen and (max-width: 1420px) {
	
	
		#mainheader{
			height: 60px;

		#logo{
			max-width: 270px;
			margin: 15px 0px 35px;
		}
	}
	
	
	#start-header .front-img{
		left: -80px;
		width: 660px;
	}
	
	.dialog-content {
	    width: 80%;
	    left: 10%;
	}
}


@media only screen and (max-width: 1250px) {
#start--header--new .h1-style {

    font-size: 4.0vw;
    margin-bottom: -8px;

}
	#start--header--new img {

    border-radius: 0 0 200px 0;

}
}




@media only screen and (max-width: 1023px) {

	.mobile-nav {
		display: unset;
	}

		#mainheader{
		
		&::before {

    display:none;
		}
	}
	#overlay-images{display:none;}
	
	.grid-container{
		padding-left: 20px;
		padding-right: 20px;
		max-width: 1023px;
	}
	
	#content{
		padding-top: 83px;
	
		.quote{
			font-size: 40px;
		}

		.quote-block {
			.quote {
				font-size: 28px;
			}

			.quote::before {
				bottom: -95px;
				font-size: 150px;
			}
			.quote::after {
				top: -125px;
    			font-size: 150px;
			}
		}

		.quote-block::after {
			right: 10%;
			border-left: 40px solid transparent;
			border-right: 40px solid transparent;
			border-top: 50px solid $green;
		}
	}
	
	#mainheader{
		position: fixed;
		
		#logo{
			width: 150px;
			margin: -15px 0px 35px;
		}
		
		#topnav{
			display: none;
		}
	}
	
	#mainnav{
		margin-top: 0px;
	
		#mobilnav-btn{
			display:block;
			position:fixed;
			right:45px;
			top: 10px;
			z-index: 100;
		}
		
		.is-drilldown {
			display: none;
			position: fixed;
			right:-230px;
			top:0;
			width: 229px;
			max-width: 229px !important;
			height: 100%;
			background-color: #fff;
			border-left: 1px solid $medium-gray;
			
			ul{
				float:none;
				min-width: auto;
				height: 100%;
				background-color: #fff;
				
				li{
					margin:0;
					
					a{
						padding: 10px 15px;
						font-size: 24px;
						color: $black;
						border: 0px;
						
						&::after {
							display: block;
							margin-top: -4px;
						}
					}
					
					&:hover>a, &.active>a{
						color: #fff;
						background-color: $primary-color;
						border: 0px;
						
						&::after{
							border-color: transparent transparent transparent #fff;
						}
						
						&::before{
							border-color: transparent #fff transparent transparent;
						}
					}
				}
			}
		}
	}
	
	#start-header{
		padding-top: 30px;
		
		.bg-img{
			display:none;
		}
		
		.front-img{
			width: 400px;
		}
		
		.h1-style{
			font-size: 48px;
		}
		
		&>.grid-container{
			padding-bottom: 20px;
		}
	
		.blue-transparent-bg, .green-transparent-bg{
			min-height: auto;
			float:none;
			padding: 20px 30px;
		}
		
		.blue-transparent-bg{
			width: auto;
			background-color: $primary-color;
			
			.flex-box{
				float:left;
			}
			
			a.cert{
				width:150px;
				margin-left: 0;
				position:static;
				float:right;
				margin: -25px 20px -20px -10px;
				
				&:hover{
					width:170px;
					margin: -35px 10px -30px -20px;
				}
			}
			
			&::after{
				content: " ";
				display:block;
				clear:both;
			}
		}
		
		.green-transparent-bg{
			width: auto;
			background-color: $secondary-color;
		}
	}
	
	#lp-header{
		padding-top: 280px;
		
		.bg-img{
			display:none;
		}
	
		.blue-transparent-bg, .green-transparent-bg{
			min-height: auto;
			float:none;
			padding: 20px 30px;
		}
		
		.blue-transparent-bg{
			width: auto;
			background-color: $primary-color;
			
			a.cert{
				width:150px;
				margin-left: 0;
				position:static;
				margin: -25px 20px 10px -10px;
				
				&:hover{
					width:170px;
					margin: -35px 10px -30px -20px;
				}
			}
		}
		
		.green-transparent-bg{
			width: auto;
			background-color: $secondary-color;
		}
	}
	
	#lp-header-img{
		.blue-transparent-bg, .green-transparent-bg{
			min-height: auto;
			float:none;
			padding: 20px 30px;
		}
		
		.blue-transparent-bg{
			width: auto;
			
			a.cert{
				width:150px;
				margin-left: 0;
				position:static;
				margin: -25px 20px 10px -10px;
				
				&:hover{
					width:170px;
					margin: -35px 10px -30px -20px;
				}
			}
		}
		
		.green-transparent-bg{
			width: auto;
			display:block;
		}
	}
	
	#contact-btn{
		top: 200px;
		font-size: 32px;
		line-height:52px;
		width: 52px;
		height: 52px;
	}
	
	#contact-content{
		top: 60px;
		padding-bottom: 52px;
		
		.blue-frame{
			margin-right: 52px;
			padding: 5px 10px 10px;
		}
	}
	
	.h4-style{
		font-size: 24px;
	}
	
	.blue-bg{
		padding: 30px 20px;
		
		.cell{
			width: 100%;
			margin-left: 0;
			padding: 0;
		}
	}
	
	.text-media-left, .text-media-right{
		&>img{
			width: 100%;
			position:static;
			margin-bottom: 20px;
		}
	}
	
	.text-login-right{
		form{
			position:static;
			width: calc(100% - 40px);
			padding: 40px 20px;
		}
		
		&>.grid-container {
	    	padding: 30px 20px;
		}
	}
	
	.green-bg{
		padding: 30px 20px;
		
		&::before, &::after{
			display:none
		}
		
		.cell{
			width: 100%;
			margin-left: 0;
			padding: 0;
		}
	}
	
	.full-image{
		height: 300px;
	}
	
	#map{
		height: 300px;
	}
	
	.yt-video{
		width: 100%;
	}
	
	.slider{
		height: 300px;
	}
	
	footer{
		padding: 30px 0;
		
		&::before, &::after{
			display:none
		}
		
		img{
			float:none;
			width: auto;
		}
		
		.large-6{
			margin-bottom: 30px;
		}
	}
	
	.dialog-content{
	    width: 90%;
	    left: 5%;
	}
	
	#search input[type="text"]{
    	padding: 8px 20px;
    	font-size: 36px;
	}
	
	#search input[type="submit"]{
    	width: calc(30% - 18px);
    	padding: 15px 12px;
       	margin-left: 0;
    	font-size: 36px;
	}
	
	#search-output{
		display:none;
	}
	
	.close {
   		top: -20px;
    	right: 0px;
	}

	#start--header--new .h1-style a {
		font-size: 22px;
		padding: 10px 20px 10px 20px;
	}
}


@media only screen and (max-width: 950px) {
	.ansprechpartner-neu .grid-margin-x > .large-6 {
    width: calc(33% - 1.36364rem);
}
}





@media only screen and (max-width: 860px) {

#start--header--new .h1-style {

    font-size: 4.5vw;
    margin-bottom: -5px;

}
	#start--header--new img {

    border-radius: 0 0 150px 0;

}

#start--header--new .h1-style {
    width: 45%;
	
	a {
		font-size: 18px;
		padding: 8px 16px 8px 16px;
	}
}

}





@media only screen and (max-width: 780px) {
		.ansprechpartner-neu .grid-margin-x > .large-6 {
	width: calc(100% - .85364rem);
			margin-bottom: 30px;
	}
	
		#start--header--new img {

    border-radius: 0 0 0px 0;

}
	
	h1, p.h1-style, h2, p.h2-style, h3, p.h3-style, h4, p.h4-style{
		-moz-hyphens: auto;
	   -o-hyphens: auto;
	   -webkit-hyphens: auto;
	   -ms-hyphens: auto;
		hyphens: auto;
	}
	
	#start-header{
		.blue-transparent-bg{
			.flex-box{
				float:none;
			}
			
			a.cert{
				float:none;
			}
		}
	}
	
	.slider{
		height: 380px;
	}
}



@media only screen and (max-width: 640px) {
	
	#mainheader #logo {
		margin: -20px 0px 15px;
	}

	#start--header--new .h1-style {
		width: 49%;

		a {
			font-size: 3vw;
			padding: 5px 9px 5px 4px;
		}
	}


	
	#mainnav #mobilnav-btn {

    right: 15px;
	
}
	#start--header--new .h1-style {

    font-size: 4.5vw;
    margin-bottom: 0px;

}
	
	
	body{
		font-size: 20px;
	}
	
	#start-header{
		.front-img{
			display:none;
		}
		
		.blue-transparent-bg, .green-transparent-bg{
			min-height: auto;
			float:none;
			padding: 20px 10px;
		}
	}
	
	#lp-header{
		padding-top: 200px;
	}
	
	#contact-btn{
		top:inherit;
		bottom: 70px;
	}
	
	#contact-content{
		top: inherit;
		bottom: 70px;
	}
	
	.h1-style{
		font-size: 42px;
	}
	
	.h4-style{
		font-size: 20px;
	}
	
	.header-img{
		display:none;
	}
	
	.full-image{
		display:none;
	}
	
	#map{
		width: calc(100% - 40px);
	}
	
	.yt-video{
		height: 340px;
	}
	
	.dialog-content{
	    width: 94%;
	    left: 3%;
	}
	
	#search input[type="text"]{
    	padding: 8px 15px;
    	font-size: 24px;
	}
	
	#search input[type="submit"]{
    	width: calc(30% - 10px);
    	padding: 13px 10px;
    	font-size: 24px;
    	text-transform: inherit;
	}

	#content .quote {
		font-size: 22px;
	}
}





@media only screen and (max-width: 500px) {
	#mainheader{
		height: 40px;

		.innerheader {
			padding: 0;
		}

		#logo {
			width: 135px;
			margin: 25px 0px 15px;
		}
		
		#topnav ul li{
			display:block;
		}
	}
	
	.slider{
		height: 640px;
	}

	footer .footer-icons i {
		margin-right: 20px;
	}
}
	
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.full-image{
		background-attachment: scroll;
	}
}