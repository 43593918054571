
footer {
    background-color: $white;
	position: relative;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
	color: $blue;
	
	&::before, &::after{
display:none;
	}
	
	&::before{
		left: 0;
		top: 0;
	}
	
	&::after{
		right: 0;
		bottom: 0;
	}
	
	a{
		color: $blue;
		
		&:hover{
			color: $green;
		}
	}
	
	p{margin-bottom:0px;}
	
	ul{
		width: 50%;
		float:left;
		margin: 0;
		padding: 0;
		line-height: 2;
		font-family: 'robotobold', Helvetica, Roboto, Arial, sans-serif;
		
		li{
			list-style: none;
			padding: 0;
		}
	}
	
	h4{
		margin-bottom: 25px;
	}
	
	.footer-icons i {
		font-size: 50px;
		margin-right: 50px;

		&:hover {
			color: $orange;
			cursor: pointer;
		}
	}

	.large-6, .large-4{
		h4{
			margin-top: 30px;
		}
		
		p{
			line-height: 2;
		}
	}
	
	.large-4 p{
		line-height: 1.4;
		margin-bottom: 15px;
	}
	
	img{
		width: 80%;
		float:right;
		max-width: 180px;
	}
	
	.smalltext {
		text-align: right;
	}

}

#sponsored{
	
	margin:30px 0;
	
	p{
    font-weight: 300;
    font-size: 20px;

	}
}