#mainheader{
	width: 100%;
	top:0;
	left:0;
	position: fixed;
	background-color: $blue;
	height: 80px;
	z-index: 1;
	
	.innerheader {
		clip-path: polygon(0 4vw, 100% 0, 100% calc(100% - 4vw), 0 100%);
		padding: 50px 0 2%;
		background-color: $white;
		margin-top: -20px;
	}
	
	.outerheader{
		clip-path: polygon(0 4vw, 100% 0, 100% calc(100% - 4vw), 0 100%);
		padding-bottom: 5px;
		transition: margin-top 0.5s;
	}
	
	.grid-container {
    max-width: 95%;
    padding: 0 !important;
}
	
	
	#logo{
		margin: 65px 120px 20px 0px;
		display:block;
		max-height: 80px;
		transition: max-height 0.5s;
		padding: 30px 0px 40px;

		img {
			max-width: 100%;
			max-height: 100px;
			transition: max-height 0.5s;
		}
	}
	
	#topnav{
		padding: 0px 0px;
		transition: margin-top 0.5s;
		margin-top: 0;
		
		ul{
			float:right;
			color:#fff;
			padding: 8px 20px 8px 0;
			margin-top: -30px;
			
			li{
				display: inline-block;
				margin-left: 20px;

				a {
					color: $orange;

					i{
						margin-right: 5px;
					}
				}

				.search-btn {
					color: $blue;
				}
				
				a:hover{
				text-decoration:underline;
				}
			}
		}
	}
}