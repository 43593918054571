#content{
	padding-top: 132px;

	.header-img{
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4vw), 0 100%);

		img {
			width: 100%;
		}
	}
	
	.quote{
		font-size: 60px;
		font-family: "Neo-Sans", "calluna-sans", Helvetica, Roboto, Arial, sans-serif;
		line-height: 1.2;
		margin:0;
		font-weight:700;
		
		.author{
			font-size: 22px;
			vertical-align: super;
		}
	}

	.small-quote {
		font-size: 30px;
	}
	
	.stars{
		font-size: 32px;
		line-height: 62px;
		
		i{
			margin-right: 10px;
		}
	}
	
	.xxlarge-8 h3{
		padding-top: 20px;
	}

	.quote-block {
		position: relative;

		.quote {
			position: relative;
			font-weight: normal;
			font-size: 36px;
			margin: 50px 0;
	
			.author {
				font-size: 18px;
			}
		}

		.quote::before {
			content: '„';
			position: absolute;
			display: inline-block;
			left: 0;
			bottom: -140px;
			font-size: 200px;
		}

		.quote::after {
			content: '“';
			position: absolute;
			display: inline-block;
			right: 0;
			top: -175px;
			font-size: 200px;
		}
	}

	.quote-block::after {
		content: '';
		position: absolute;
		display: inline-block;
		right: 20%;
		bottom: 0;
		width: 0;
		height: 0;
		border-left: 80px solid transparent;
		border-right: 80px solid transparent;
		border-top: 100px solid $green;
	}

	ul {
		margin-bottom: 1.5rem;
	}
	
	li {
		padding-left: 30px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

#breadcrumb{
	color: $primary-color;
	font-size: 18px;
	margin: 10px 0;
	
	i.fa-chevron-right{
		font-size: 16px;
		margin: 0 5px;
	}
}

#totop{
	position:fixed;
	right: 20px;
	bottom: 20px;
	font-size:24px;
	color: $primary-color;
	z-index: 100;
	background-color: #fff;
	line-height:32px;
	width: 32px;
	height: 32px;
	border-radius: 20px;
	text-align:center;
	
	&:hover{
		color: #fff;
		background-color: $primary-color;
	}
}

#contact-btn{
	position:fixed;
	right: 0px;
	top: 520px;
	font-size: 42px;
	color: $white;
	z-index: 100;
	background-color: $primary-color;
	line-height:72px;
	width: 72px;
	height: 72px;
	text-align:center;
	display:block;
	
	img{
		vertical-align: top;
	}
	
	&:hover{
		color: $white;
		background-color: $secondary-color;
	}
}

#contact-content{
	display:none;
	position:fixed;
	right: 0px;
	top: 362px;
	color: $white;
	z-index: 100;
	padding-bottom: 72px;
	
	.blue-frame{
		margin-right: 72px; 
		padding: 10px 20px 15px;
		background-color: $primary-color;
	}
	
	p{
		margin:0;
	}
	
	a{
		color: $white;
		
		&:hover{
			text-decoration: underline;
		}
	}
}

#start-header {

    position: relative;
    z-index: 10;
    padding-top: 0px;
    background-position: bottom;
    background-size: cover;
    padding-top: 140px;
    height: 770px;
    background-repeat: no-repeat;
	border-radius: 0 0 250px 0;

	
	.bg-img{
		position:absolute;
		z-index:-1;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		overflow:hidden;
		
		img, video{
			position:absolute;
			bottom:0;
			left: 50%;
			margin-left: -960px;
			width: 1920px;
			max-width: 1920px;
			height: inherit;
		}
	}
	
	.stage--title {

    position: absolute;
    bottom: 0;
    padding-bottom: 0px!important;
    vertical-align: bottom;
    margin-bottom: -36px;
    width: 100%;
    max-width: 100%;

}
	
	.front-img{
		position:absolute;
		top: -30px;
		left:0;
		z-index: 1;
		width: 1180px;
		max-height: calc(100% + 30px);
		overflow: hidden;
	}
	
	&>.grid-container{
		padding-bottom: 40px;
	}
	
	.h1-style p{
		font-size: 90px;
		line-height: 1.0;
		font-weight: 700;
		color:$white;
		text-align: center;
		text-transform: uppercase;
	}
	
	.blue-transparent-bg, .green-transparent-bg{
		position: relative;
		z-index: 15;
		float:left;
		box-sizing: content-box;
		min-height: 130px;
	}
	
	.blue-transparent-bg{
		width: 817px;
		padding: 40px 250px 40px calc((100% - 1570px)/2);
		position: relative;
		z-index: 16;
		
		a.cert{
			width:180px;
			right: -90px;
			position: absolute;
			top: 5px;
			display:block;
			
			&:hover{
				width:200px;
				right: -100px;
			}
		}
	}
	

	
	.green-transparent-bg{
		width: 363px;
		padding: 40px calc((100% - 1570px)/2) 40px 140px;
		position:relative;
		display:block;
		
		&:hover{
			color: $white;
			background-color: $primary-color;
			
			span.btn{
				color: $white;
			}
		}
	}
}

#lp-header{
	position: relative;
	z-index:10;
	padding-top: 635px;
	background-position: bottom;
	background-size: cover;
	
	.bg-img{
		position:absolute;
		z-index:-1;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		overflow:hidden;
		
		img, video{
			position:absolute;
			top:0;
			left: 50%;
			margin-left: -960px;
			width: 1920px;
			max-width: 1920px;
		}
	}
	
	.blue-transparent-bg, .green-transparent-bg{
		position: relative;
		z-index: 15;
		float:left;
		box-sizing: content-box;
		min-height: 130px;
	}
	
	.blue-transparent-bg{
		width: 817px;
		padding: 40px 250px 40px calc((100% - 1570px)/2);
		z-index: 16;
		
		p.h1-style{
			font-size: 64px;
		}
		
		a.cert{
			width:180px;
			right: -90px;
			position: absolute;
			top: 5px;
			display:block;
			
			&:hover{
				width:200px;
				right: -100px;

			}
		}
	}
	
	.green-transparent-bg{
		width: 363px;
		padding: 40px calc((100% - 1570px)/2) 40px 140px;
		position:relative;
		display:block;
		
		&:hover{
			color: $white;
			background-color: $primary-color;
			
			span.btn{
				color: $white;
			}
		}
	}
}

#lp-header-img{
	position: relative;
	z-index:10;
	background-position: bottom;
	background-size: cover;
	
	.bg-img{
		width: 100%;
	}
	
	.blue-transparent-bg, .green-transparent-bg{
		box-sizing: content-box;
		min-height: 130px;
		float:left;
	}
	
	.blue-transparent-bg{
		width: 817px;
		padding: 40px 250px 40px calc((100% - 1570px)/2);
		background-color: $primary-color;
		float:left;
		
		p.h1-style{
			font-size: 64px;
		}
		
		a.cert{
			width:180px;
			left: calc(((100% - 1570px)/2) + 980px);
			position: absolute;
			bottom: 30px;
			display:block;
			
			&:hover{
				width:200px;
				right: -100px;
	
			}
		}
	}
	
	.green-transparent-bg{
		width: 363px;
		padding: 40px calc((100% - 1570px)/2) 40px 140px;
		background-color: $secondary-color;
		float:left;
		
		&:hover{
			color: $white;
			background-color: $primary-color;
			
			span.btn{
				color: $white;
			}
		}
	}
}

.white-bg{
	padding: 50px 0;
	
	&.no-top-padding{
		padding-top: 0;
	}
	
	&.no-bottom-padding{
		padding-bottom: 0;
	}
}

.blue-bg{
	position: relative;
	padding: 100px 0 50px;
	max-width: 100%;
	margin: 50px 0 0;
	background-color: $blue;
	clip-path: polygon(0 4vw, 100% 0, 100% calc(100% - 4vw), 0 100%);
	
	.cell{
		color: $white;
		max-width: $global-width;
		margin: 0 auto;

		h4 {
			color: $white;
		}

		.btn {
			background-color: $orange;
		}

		.btn:hover {
			color: $orange;
			background-color: $white;
		}
	}
}

.text-media-left, .text-media-right{
	position: relative;
	overflow:hidden;
	min-height: 340px;
	
	&>img{
		position:absolute;
		top: 0;
		width: calc(50% - 160px);
	}
	
	.cell{
		padding: 20px 0;
	}
}


.text-media-left{
	&>img{
		left: 0;
	}

	.large-7 {
		padding-left: 2% !important;
	}
}


.text-media-right{
	&>img{
		right: 0;
	}

	.large-7 {
		padding-right: 2% !important;
	}
}


.text-login-right{
	position: relative;
	min-height: 622px;
	
	form{
		position:absolute;
		top: 0;
		right: 0;
		width: 413px;
		padding: 80px calc((100% - 1570px)/2) 60px 90px;
		background-color: $secondary-color;
		box-sizing: content-box;
		
		p{
			color: $white;
			
			&.smalltext{
				font-size: 16px;
				margin-top: 20px;
				margin-bottom: 0px;
			}
		}
		
		.error{
			border: 2px solid $alert-color;
		}
	}
	
	&>.grid-container{
		padding: 60px 0;
	}
}


.card{
	max-width: 512px;
}

a.card{
	margin: 15px auto;
	position:relative;
	height: calc(100% - 30px);
	
	&::before{
		content: " ";
		width: 40px;
		height: 40px;
		position:absolute;
		right: 0;
		top: 0;
		background-color: $secondary-color;
		z-index: 10;
	}
	
	h3{
		margin-bottom: 30px;
	}
	
	span.btn{
		color: $white;
	}
	
	&:hover{
		box-shadow: 0px 5px 5px #999;
		color: inherit;
	}
}

div.card{
	border:none;
	
	.card-section{
		padding: 1rem 0;
	}
}

div.teaser-card{
	margin: 15px auto;
	position:relative;
	height: 100%;
	border: 1px solid rgba(0,0,0,.16);
	max-width: inherit;
	
	&::before{
		content: " ";
		width: 40px;
		height: 40px;
		position:absolute;
		right: 0;
		top: 0;
		background-color: $secondary-color;
		z-index: 10;
	}
	
	h3{
		margin-bottom: 30px;
	}
	
	.card-section{
		padding: 1rem;
	}

	.icon-frame {
		line-height: 220px;
		height: 220px;
	}
}

.card .icon-frame{
	text-align: center;
	font-size: 56px;
	line-height: 320px;
	width: 100%;
	height: 320px;
	overflow:hidden;
	background-color: $primary-color;
	position:relative;
	color: $white;
	margin-bottom: 0;
	
	img{
		width: 511px;
		position:absolute;
		left: 50%;
		margin-left: -255px;
		max-width: inherit;
	}
}


.medium-6 .card{
	margin: 0 auto;
}


.start-news{
	max-width: 100%;
	height: 320px !important;
	overflow: hidden;
	
	.icon-frame{
		width: 511px;
		left: 0;
		position: absolute;
	}
	
	.card-section{
		padding-left: 540px;
	}
}


.news-date{
	margin-top: -25px;
}


.news-btn{
	text-align:center;
	
	.btn{
		margin: 20px;
		display: inline-block;
	}
}


.green-bg{
	background-attachment: fixed !important;
	position: relative;
	padding: 72px 0;
	max-width: 100%;
	margin:0;
	background-color: $green;
	clip-path: polygon(0 4vw, 100% 0, 100% calc(100% - 4vw), 0 100%);

	.grid-x {
		max-width: 1500px;
		margin: auto;
	}

	#formular {
		margin-top: 50px;
	}
	
	.cell{
		color: $white;
		max-width: $global-width;
		margin: 0 auto;

		.slider {
			margin-top: 50px;
			height: 160px;
			margin-bottom: 50px;
		}

		p {
			font-size: 32px;
			font-weight: 100;
		}
	}
}

.full-image{
	width: 100%;
	height: 500px;
	background-attachment: fixed;
	background-position: center top;
    background-repeat: no-repeat;
	background-size: cover;
}


#map{
	width: 100%;
	height: 500px;
	z-index:0;
	
	.leaflet-div-icon{
		border: 0px;
		background-color: transparent;
		
		i{
			color: $primary-color;
			font-size: 48px;
		}
	}
}


.slider{
	width: 100%;
	height: 360px;
	position:relative;
	
	.slider-elem{
		width: 100%;
		height: auto;
		position:absolute;
		left: 200%;
		top: 0;
		
		&:first-child{
			left: 0;
		}
	}
	
	.slider-nav{
		position:absolute;
		display:inline-block;
		color: $white;
		top: 50%;
		margin-top: -28px;
		font-size: 56px;
		line-height: 56px;
		
		&.left-arrow{
			left: -70px;
		}
		
		&.right-arrow{
			right: -70px;
		}
	}
}

#start--header--new{
	position:relative;
	z-index: 1;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4vw), 0 100%);
	margin-bottom: -4.01vw;
}

#start--header--new img{
	border-radius: 0 0 0px 0;
	width: 100%;
}

#start--header--new .h1-style {
	position: absolute;
	top: 15%;
	width: 40%;
	left: 50%;
	text-align: left;
	letter-spacing: 0px;
	color: $blue;
	text-transform: none;
	line-height: 1;
	font-weight: 400;

	p {
		font-size: 4vw;
	}

	a {
		font-size: 30px;
		padding: 20px 40px 20px 40px;
	}

	.orange {
		background-color: $orange;
		color: $white;
	}

	.orange:hover {
		background-color: $white;
		color: $orange;
	}
}

.btn {
    color: $white;
    background-color: transparent;
    border-radius: 0px;
    padding: 6px 22px 8px 18px;
    display: inline-block;
    margin-bottom: 10px;
	background-color:$blue;

}

.btn:hover {
	color: $white;
	background-color: transparent;
	background-color:$green;
   

}

.btn::before {

    content: '';
}

a.card::before, .green-bg::before, .green-bg::after, footer::before, footer::after, div.teaser-card::before {
    display: none; 
}

.aehre, .kartoffel{z-index:10!important;}




.green-bg h1, .green-bg h2, .green-bg h3, .green-bg h4, .green-bg h5, .green-bg h6 {

    text-transform: none;
    font-size: 50px;
    font-weight: 300;
    margin-top: -20px;

}

.news-date{color:$primary-color;margin: 20px 0 0;}

.primary--color{color:$primary-color;}

.ansprechpartner-neu .card {border: 1px solid rgba(0,0,0,.16);}

.ansprechpartner-neu div.card .card-section {

    padding: 1rem;

}

.logo-auflistung-neu .card {

    max-width: 512px;
    align-items: center;
    justify-content: center;

}

.logo-auflistung-neu img {

    width: 60%;
    margin: 0 auto;
    vertical-align: middle;

}

.logo-auflistung-neu a{text-align:center;}


.logo-auflistung-neu .grid-margin-x > .cell {

    margin: .68182rem;

}


p img {
max-width:100%;
}


.card img{flex-shrink:0!important;}

.start-news {
    height: auto!important;
}

.logo-auflistung-neu a{height:100%!important;}

.start-video-frame {
	display: none;
	background-color:rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    text-align: center;
	padding: 50px 25px;
	z-index: 99;
	
	iframe {
		margin: auto;
	}

	i {
		color: $white;
		font-size: 50px;
		margin-left: -19px;
		position: relative;
		vertical-align: top;
		margin-top: -24px;
		cursor: pointer;
	}

	i:hover {
		color: $orange;
	}
}