#mainnav{
	margin-top: 25px;
	transition: margin-top 0.5s;
	
	#mobilnav-btn{
		display:none;

		color: $blue;
	}
	
	ul{
		float:right;
	
		li{
			
			a{
				padding: 0px 0px 10px 0px;
				font-size: 24px;
				color: $blue;
				
				&::after {
    				display: none;
				}
			}
			
			&:hover>a{
				color: $green;
				background-color:transparent;
			}
			
			ul{
				min-width: 220px;
				border: none;
				background-color: #999;
				
				li{
					margin-left: 0;
					
					a{
						padding: 8px 20px;
						border-bottom: 0;
						font-size: 22px;
						color: $blue;
					}
					
					&:hover a, &.active a{
						border-bottom: 0;
						color: $green;
						background-color: $secondary-color;
					}					
				}
			}
		}
	}
}

#mainnav ul li ul li:hover ul li a{background:transparent;}
#mainnav ul li ul li a:hover ul li a{background:transparent;}
#mainnav ul li ul li ul li a:hover{background:$secondary-color;}

#mainnav li.active>a{
				color: $green;
				background-color:transparent;
			} 



#mainnav>ul li a{			margin-bottom:0px;}
#mainnav>ul>li>a{			margin-bottom:10px;}

#mainheader ul li:before {
	display: none;
}

.mobile-nav {
	display: none;
	width: 0;
	right: 230px;
	position: fixed;
	z-index: 1;
	height: 100%;

	.is-drilldown {
		width: 230px;
		max-width: 230px !important;
		right: -230px;
		height: 100%;
		border-left: 5px solid $green;

		ul {
			background-color: $white;
			

			li a {
				color: $blue;
				background: none;
			}

			li a:hover {
				color: $green;
				background: none;
			}
		}
	}
}