body, html{
	width: 100%;
	overflow-x:hidden;
}

.clear{
	width: 100%;
	clear:both;
}

.left{
	float:left;
}

.right{
	float:right;
}

.center{
	text-align:center;
}

ul{
	
	li{
		line-height: 1.3;
	}
}

nav ul{
	margin: 0;
	padding: 0;
}

nav ul li{
	list-style:none;
	line-height: 1.4;
	margin: 0;
}

.h1-style{
	font-size: 42px;
}

.h4-style{
	font-size: 30px;
}

h1.wo-subtitle, h2.wo-subtitle, h3{
	margin-bottom: 30px;
}

.subtitle{
	color: $green;
	font-size: 28px;
	line-height: 1.2;
	margin-bottom: 1.2rem;
	font-weight:300;
}

.bigtext{
	font-size: 30px;
	line-height: 1.2;
}

.smalltext{
	font-size: 20px;
}

.blue-transparent-bg{
	background-color: rgba(3, 146, 183, 0.8);
	color: #fff;
	
	.subtitle, .btn{
		color:#fff;
	}
}

.green-transparent-bg{
	background-color: rgba(3, 183, 134, 0.8);
	color: #fff;
	
	.subtitle, .btn{
		color:#fff;
	}
	
	.btn:hover{
		color: $primary-color;
	}
}

.flex-box{
	display:flex;
	
	.col{
		width: 100%;
	}
}

.btn{
	color: $white;
	background-color: $blue;
	border-radius: 5px;
	padding: 6px 18px;
	display:inline-block;
	margin-bottom: 10px;
	
	&::before{
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		content: '\f0a9';
		margin-right: 5px;
	}
	
	&:hover{
		color: $white;
		background-color: $green;
	}

	&.orange {
		background-color: $orange;

		&:hover {
			color: $orange;
			background-color: $white;
		}
	}
}

form{
	input{
		margin-bottom: 8px !important;
	}

	button{
		display:block;
		width: 100%;
		text-align: center;
		color: $orange;
		background-color: $white;
		padding: 18px 0;
		margin: 15px 0;
		cursor: pointer;
		
		&::before{
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			content: '\f0a9';
			margin-right: 5px;
		}
		
		&:hover{
			color: $white;
			background-color: $orange;
		}
	}
	
	label.check-label{
		position:relative;
		padding-left: 35px;
		font-size: rem-calc(18);
		line-height: 1.4;
		
		input{
			display:none;
		}
		
		a{
			color: $white;
			text-decoration: underline;
			
			&:hover{
				color: $primary-color;
			}
		}
		
		&::before {
		  	width: 25px;
		  	height: 25px;
		  	background-color: $white;
		  	display: block;
		  	content: "";
		  	position: absolute;
		  	left: 0;
		  	top: 2px;
		}
		
		&.active-check::before {
		  	box-shadow: inset 0px 0px 0px 3px $white;
  			background-color: $primary-color;
		}
		
		&:hover{
		  	cursor: pointer;
		}
	}
}

.greenbg{
	background-color: $secondary-color;
	padding: 40px 0;
	color: $white;
}

h1,h2,.h1-style{
	text-transform: uppercase;
	font-weight: 700;
	hyphens:auto;
}

h1, h2, h3, h4, h5, h6 {
	color: $blue;

	&.blue {
		color: $blue;
	}

	&.green {
		color: $green;
	}

	&.orange {
		color: $orange;
	}
}

@font-face {
	font-family: "Neo-Sans";
    src:url(/assets/fonts/OpenSans-Regular.ttf) format("opentype");
}

ul {
	list-style: none;
	padding: 0;
	margin:	0;

	li {
		padding-left: 1.5em;
		margin-bottom: 20px;
	}

	li:before {
		content: "\f058"; 
		font-family: 'Font Awesome 5 Free';
    	font-weight: 900;
		display: inline-block; 
		color: $green;
		margin-left: -30px;
    	width: 30px;
	}
}

.no-border {
	border: none !important;
}

.margin-bottom {
	margin-bottom: 30px;
}

.no-break {
	white-space: nowrap;
}